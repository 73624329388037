import api from "./api-service";
import { stringify } from "querystring";

const url = "resolution-zones";

export const find = (filter) => {
  return api().get(`${url}?${stringify(filter)}`);
};

export const save = (resolutionZone) => {
  return api().post(url, resolutionZone);
};

export const findOne = (id) => {
  return api().get(`${url}/${id}`);
};
