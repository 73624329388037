<template>
  <div>
    <v-container>
      <perseu-card title="Zonas de Resolução">
        <template slot="title-right">
          <v-btn @click="create" color="secondary"
            >Nova Zona de Resolução</v-btn
          >
        </template>
        <template slot="content">
          <v-switch v-model="query.active" label="Ativos" @change="find" />
          <v-data-table :items="items" :headers="headers" class="elevation-1">
            <template v-slot:item.actions="{ item }">
              <table-action-button
                text="Editar"
                @click="edit(item)"
                icon="edit"
              />
              <table-action-button
                v-if="item.active"
                text="Desativar"
                @click="activeOrDeactivate(item, false)"
                icon="delete"
              />
              <table-action-button
                v-else
                text="Ativar"
                @click="activeOrDeactivate(item, true)"
                icon="check"
              />
            </template>
          </v-data-table>
        </template>
      </perseu-card>
    </v-container>
    <v-dialog v-if="dialogForm" v-model="dialogForm" max-width="800">
      <form-resolution-zone
        v-if="dialogForm"
        v-model="item"
        @created="createdForm"
        @updated="updatedForm"
      />
    </v-dialog>
  </div>
</template>

<script>
import { find, save, findOne } from "@/services/resolution-zone-service.js";

export default {
  components: {
    "form-resolution-zone": () => import("@/components/ResolutionZone/Form"),
  },
  data: () => ({
    items: [],
    item: {},
    headers: [
      { text: "Nome", value: "name" },
      {
        text: "Ações",
        value: "actions",
        width: 150,
        sortable: false,
        align: "start",
      },
    ],
    query: {
      active: true,
    },
    dialogForm: false,
    selectedIndex: -1,
  }),
  created() {
    this.find();
  },
  methods: {
    findItemIndex(id) {
      return this.items.findIndex((item) => item.id === id);
    },
    async find() {
      try {
        this.$store.dispatch("loading/openDialog");
        const { data } = await find(this.query);
        this.items = data;
      } catch (error) {
        this.$errorHandler(error);
      } finally {
        this.$store.dispatch("loading/closeDialog");
      }
    },
    async activeOrDeactivate(item, option) {
      try {
        this.$store.dispatch("loading/openDialog");
        item.active = option;
        const { data } = await save(item);
        this.items.splice(this.findItemIndex(item.id), 1);
      } catch (e) {
        this.$errorHandler(e);
      } finally {
        this.$store.dispatch("loading/closeDialog");
      }
    },
    async edit(item) {
      try {
        const { data } = await findOne(item.id);
        this.selectedIndex = this.findItemIndex(item.id);
        this.item = data;
        this.dialogForm = true;
      } catch (e) {
        this.$errorHandler(e);
      } finally {
        this.$store.dispatch("loading/closeDialog");
      }
    },
    create() {
      this.item = {};
      this.dialogForm = true;
    },
    createdForm(item) {
      this.items.push(item);
      this.dialogForm = false;
    },
    updatedForm(item) {
      this.items.splice(this.selectedIndex, 1, item);
      this.dialogForm = false;
      this.selectedIndex = -1;
    },
  },
};
</script>

<style></style>
